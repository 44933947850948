function createPostGridCarousel(element) {
  const carouselItems = element.querySelectorAll('article')
  const carouselMax = carouselItems.length - 1
  const io = new IntersectionObserver(setDisabled, { threshold: [1] })

  let carouselCount = 0
  let buttons = []

  function goToSlide(direction) {
    if(direction) carouselCount += direction
    if(carouselCount < 0) carouselCount = 0
    if(carouselCount > carouselMax) carouselCount = carouselMax
    carouselItems[0].style.marginLeft = `${carouselItems[0].offsetWidth * carouselCount * -1}px`
  }

  
  function setDisabled(entries) {
    entries.forEach(entry => {
      const index = [...carouselItems].indexOf(entry.target)
      const button = index === 0 ? buttons[0] : buttons[buttons.length - 1]
      const action = entry.isIntersecting ? 'add' : 'remove'
      button.classList[action]('disabled')
    })
  }

  function createButtons() {
    const nextButton = document.createElement('button')
    const prevButton = document.createElement('button')
    buttons = [prevButton, nextButton]
    nextButton.innerHTML = 'Next'
    prevButton.innerHTML = 'Prev'
    nextButton.addEventListener('click', goToSlide.bind(null, +1))
    prevButton.addEventListener('click', goToSlide.bind(null, -1))
    nextButton.classList.add('kd-slider-button', 'kd-slider-button--next')
    prevButton.classList.add('kd-slider-button', 'kd-slider-button--prev')
    element.appendChild(prevButton)
    element.appendChild(nextButton)
    window.addEventListener('resize', goToSlide.bind(null, undefined))
  }

  io.observe(carouselItems[0]);
  io.observe(carouselItems[carouselItems.length - 1])
  createButtons()
}

document.addEventListener('DOMContentLoaded', function() {
const styleSliders = document.querySelectorAll('.is-style-slider')
const sliderInstances = [...styleSliders].map(s => createPostGridCarousel(s))

console.log(styleSliders);
});